'use client'

import ReactDOM from 'react-dom'

export function PreloadResources() {
    // @ts-ignore
    ReactDOM.preconnect('https://cdn.sanity.io/')
    // @ts-ignore
    ReactDOM.prefetchDNS('https://cdn.sanity.io/')

    return null
}